// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-scale {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  height: 30px;
}

.time-label {
  position: absolute;
  white-space: nowrap;
  text-align: center;
  width: 40px;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/Modules/CanvasChart/TimeScale/TimeScale.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACJ","sourcesContent":[".time-scale {\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    font-size: 12px;\n    height: 30px; \n}\n\n.time-label {\n    position: absolute;\n    white-space: nowrap;\n    text-align: center;\n    width: 40px;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
