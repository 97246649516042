// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-scale {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  color: white;
}

.price-tick {
  text-align: right;
  position: absolute;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/Modules/CanvasChart/PriceScale/PriceScale.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAEA;EACI,iBAAA;EACA,kBAAA;EACA,QAAA;AACJ","sourcesContent":[".price-scale {\n    position: absolute;\n    right: 0;\n    top: 0;\n    width: 50px;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    font-size: 12px;\n    color: white;\n}\n\n.price-tick {\n    text-align: right;\n    position: absolute;\n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
