import moment from "moment";
import CandleJapaneeseChart, { Candle } from "./CandleJapaneeseChart/CandleJapaneeseChart";
import { DATE_FORMAT } from "../Candles/constants";
import { CandleService } from "../../services/candleService";
import { useEffect, useRef, useState } from "react";
import { UCandle } from "../../CandlesClient/models/Candle";
import { getParsedResponseCandleRows } from "../Candles/helper";
import { zoomInStep, zoomOutStep } from "./constants";

const CanvasChart = () => {
    const [candles, setCandles] = useState<Candle[]>([]);
    const chartRef = useRef<HTMLDivElement>(null);
    const isDraggingRef = useRef<boolean>(false);
    const dragPosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
    const [canvasSize, setCanvasSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
    const [cameraOffsetX, setCameraOffsetX] = useState<number>(0);
    const [cameraOffsetY, setCameraOffsetY] = useState<number>(0);
    const [zoomLevel, setZoomLevel] = useState<number>(1);
    const [cameraLeft, setCameraLeft] = useState<number>(0);
    const [cameraRight, setCameraRight] = useState<number>(0);

    const initCandles = (): void => {
        const dateTo = moment().utc().format(DATE_FORMAT);

        CandleService.getCandles(dateTo, -1000, 60)
            .then(response => {
                const data = response?.results?.A?.frames[0]?.data?.values;
                const UIcandles: UCandle[] = getParsedResponseCandleRows(data);
                setCandles(UIcandles);

                if (data == null || data.length == 0) {
                    return;
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        initCandles();
    }, []);

    useEffect(() => {
        const resizeHandler = (): void => {
            const container = document.querySelector<HTMLElement>('.canvas-container');
            const size = {
                width: container?.offsetWidth || 0,
                height: container?.offsetHeight || 0
            }
            setCanvasSize(size);
            setCameraLeft(-size.width / 2);
            setCameraRight(size.width / 2);
        };

        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    useEffect(() => {
        const onMouseDown = (event: MouseEvent): void => {
            isDraggingRef.current = true;
            dragPosition.current = { x: event.clientX, y: event.clientY };
        };

        const onMouseMove = (event: MouseEvent): void => {
            if (!isDraggingRef.current) return;

            const deltaX = (event.clientX - dragPosition.current.x) / 1;
            const deltaY = (event.clientY - dragPosition.current.y) / 1;
            dragPosition.current = { x: event.clientX, y: event.clientY };

            setCameraOffsetX((prevValue) => prevValue - deltaX);
            setCameraOffsetY((prevValue) => prevValue + deltaY);
        };

        const onMouseUp = (): void => {
            isDraggingRef.current = false;
        };

        
        const onMouseLeave = (): void => {
            isDraggingRef.current = false;
        };

        const onMouseWheel = (event: WheelEvent): void => {
            const delta = event.deltaY > 0 ? zoomInStep : zoomOutStep;
            console.log(cameraLeft);
            setCameraLeft((prevValue => prevValue * delta));
            setCameraRight((prevValue => prevValue * delta));
            setZoomLevel((prevZoom) => prevZoom * (1 / delta));
        };

        if (chartRef.current == null) {
            return;
        }

        chartRef.current.addEventListener('mousedown', onMouseDown);
        chartRef.current.addEventListener('mousemove', onMouseMove);
        chartRef.current.addEventListener('mouseup', onMouseUp);
        chartRef.current.addEventListener('wheel', onMouseWheel);
        chartRef.current.addEventListener('mouseleave', onMouseLeave);


        return (): void => {
            if (chartRef.current == null) {
                return;
            }

            chartRef.current.removeEventListener('mousedown', onMouseDown);
            chartRef.current.removeEventListener('mousemove', onMouseMove);
            chartRef.current.removeEventListener('mouseup', onMouseUp);
            chartRef.current.removeEventListener('wheel', onMouseWheel);
            chartRef.current.removeEventListener('mouseleave', onMouseLeave);
        };
    }, [candles, canvasSize]);

    return (
        <div className="canvas-container" ref={chartRef}>
            <CandleJapaneeseChart
                candles={candles}
                canvasSize={canvasSize}
                zoomLevel={zoomLevel}
                cameraRight={cameraRight}
                cameraLeft={cameraLeft}
                cameraOffsetX={cameraOffsetX}
                cameraOffsetY={cameraOffsetY} />
        </div>

    );
};

export default CanvasChart;