import React from "react";
import { DATE_FORMAT } from "../../Candles/constants";
import "./TimeScale.scss";
import moment from "moment";
import { candleGap, candleWidth } from "../constants";

export interface TimeValue {
    id: number;
    timeClose: number; // timestamp
}

interface TimeScaleProps {
    timeValues: TimeValue[];
    panelWidth: number;
    zoom: number;
    positionX: number;
}

const TimeScale: React.FC<TimeScaleProps> = ({ timeValues, panelWidth, zoom, positionX }) => {
    const skirFrequency = Math.round(panelWidth / ((candleWidth + candleGap) * zoom) / 10);
    
    return (
            <div className="time-scale">
                {timeValues.map((value, index) => {
                    if(index % skirFrequency !== 0) {
                        return;
                    }
                    const xPosition = index * (candleWidth + candleGap) - panelWidth / 2;

                    const adjustedXPosition = (xPosition - positionX) * zoom;
                    const timeLabel = moment.unix(value.timeClose / 1000).format(DATE_FORMAT);
                    return (
                        <div
                            key={value.id}
                            className="time-label"
                            style={{
                                left: `${adjustedXPosition + panelWidth / 2}px`,
                            }}
                        >
                            {timeLabel}
                        </div>
                    );
                })}
            </div>
    );
};

export default TimeScale;
