import React, { LegacyRef } from 'react';
import moment from 'moment';
import "./TimeScale.scss";

interface TimeScaleProps {
    startDate: number;
    endDate: number;
    tickCount: number;
    dateFormat: string;
    elementRef: LegacyRef<HTMLDivElement>;
}

const TimeScale: React.FC<TimeScaleProps> = ({ startDate, endDate, tickCount, dateFormat, elementRef }) => {
    const ticks: number[] = [];

    const dateFrom = startDate * 1000;
    const dateTo = endDate * 1000;

    const interval = (dateTo - dateFrom) / (tickCount - 1);

    for (let i = 0; i < tickCount; i++) {
        const timestamp = dateFrom + i * interval;
        ticks.push(timestamp);
    }

    return (
        <div className='time-scale-panel' ref={elementRef}>
            {ticks.map((timestamp, index) => {
                const formattedDate = moment(timestamp).format(dateFormat);
                return (
                    <div key={index} className='value'>{formattedDate}</div>
                );
            })}
        </div>
    );
};

export default TimeScale;
