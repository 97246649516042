// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-scale-panel {
  position: absolute;
  right: 0;
  top: 0;
  height: 90%;
  width: 70px;
  text-align: center;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  cursor: row-resize;
  z-index: 1;
}
.price-scale-panel .tick {
  position: absolute;
  transform: translateX(-50%);
  text-align: center;
  font-size: 12px;
  display: inline-block;
  color: #d1f7ff;
  font-size: 12px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Modules/CanvasChart/SCI/PriceScale/PriceScale.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,MAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,0CAAA;EACA,0BAAA;EACA,kBAAA;EACA,UAAA;AACJ;AACI;EACI,kBAAA;EACA,2BAAA;EACA,kBAAA;EACA,eAAA;EACA,qBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AACR","sourcesContent":[".price-scale-panel {\n    position: absolute;\n    right: 0;\n    top: 0;\n    height: 90%;\n    width: 70px;\n    text-align: center;\n    overflow: hidden;\n    background-color: rgba(255, 255, 255, 0.1);\n    backdrop-filter: blur(2px);\n    cursor: row-resize;\n    z-index: 1;\n\n    .tick {\n        position: absolute;\n        transform: translateX(-50%);\n        text-align: center;\n        font-size: 12px;\n        display: inline-block;\n        color: #d1f7ff;\n        font-size: 12px;\n        font-weight: 500;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
