import React, { useEffect, useState } from "react";
import * as THREE from "three";
import "./CanvasDemo.scss";
import { Point } from "../../CandlesClient/models/Candle";


export const CanvasDemo = () => {
    const [cameraPositionZ, setCameraPositionZ] = useState<number>(1000);
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [camera, setCamera] = useState<THREE.OrthographicCamera>(new THREE.OrthographicCamera(0, 0, 0, 0, 0, 0));
    const [scene, setScene] = useState<THREE.Scene>(new THREE.Scene());
    const [renderer, setRenderer] = useState<THREE.WebGLRenderer | null>();
    const [chartMouseDown, setChartMouseDown] = useState<boolean>(false);
    const [mousePosition, setMousePosition] = useState<Point>({ x: 0, y: 0 });


    const scaleCanvas = (e: any) => {
        const scaleStepValue = e.deltaY < 0 ? -100 : 100;

        //setCameraPositionZ(cameraPositionZ + scaleStepValue);
    }

    const chartMouseDownHandler = (e: any) => {
        setChartMouseDown(true);
        setMousePosition({ x: e.clientX, y: e.clientY });
    }

    const canvasMouseUpHandler = () => {
        setChartMouseDown(false);
    }

    const canvasMouseMoveHandler = (e: any) => {
        if (!chartMouseDown) {
            return;
        }

        const stepValue = mousePosition.x > e.clientX ? 10 : -10
        //camera.position.x += stepValue;
        setMousePosition({ x: e.clientX, y: e.clientY });
    }

    const loopRender = (renderer: THREE.WebGLRenderer, scene: THREE.Scene, camera: THREE.OrthographicCamera, mesh: THREE.Mesh) => {
        mesh.rotation.y += 0.001;
        //camera.position.x += 1;
        renderer.render(scene, camera);

        requestAnimationFrame(() => {
            loopRender(renderer, scene, camera, mesh);
        })
    }

    const setCanvasSize = () => {
        const container = document.querySelector<HTMLElement>('.canvas-container');

        const canvasWidth = container?.offsetWidth || 0;
        const canvasHeight = container?.offsetHeight || 0;
        setWidth(canvasWidth);
        setHeight(canvasHeight);
    }

    useEffect(() => {
        camera.position.z = cameraPositionZ;
    }, [cameraPositionZ])

    useEffect(() => {
        setCanvasSize();


    }, []);


    useEffect(() => {
        if (renderer == null) {
            return;
        }

        //const camera = new THREE.PerspectiveCamera(45, width / height, 0.01, 50000);
        // camera.position.set(0, 0, cameraPositionZ);
        // setCamera(camera);

        const camera = new THREE.OrthographicCamera(width / - 2, width / 2, height / 2, height / - 2, height / - 2, width / 2);
        scene.add(camera);
        setCamera(camera);

        const light = new THREE.AmbientLight(0xffffff);
        scene.add(light);

        //const geometry = new THREE.SphereGeometry(300, 12, 12);
        const geometry = new THREE.PlaneGeometry(100, 200);
        const material = new THREE.MeshBasicMaterial({ color: 0xc3c3c3, wireframe: false });
        const mesh = new THREE.Mesh(geometry, material);
        //mesh.position.z = 100;
        scene.add(mesh);

        renderer.render(scene, camera);
        //loopRender(renderer, scene, camera, mesh);
        console.log("RENDER");
    }, [renderer]);

    useEffect(() => {
        if (width === 0 || height === 0) {
            return;
        }

        camera.left = width / -2;
        camera.right = width / 2;
        camera.top = height / 2;
        camera.bottom = height / -2;
        camera.near = height / - 2
        camera.far = width / 2;
        camera.updateProjectionMatrix();

        if (renderer != null) {
            renderer.setSize(width, height);
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
            renderer.render(scene, camera);
        } else {
            const canvas = document.getElementById('canvas') as HTMLCanvasElement;
            const r = new THREE.WebGLRenderer({ canvas: canvas });
            r.setClearColor(0x0c0c0c);
            setRenderer(r);
        }
    }, [width, height])

    window.onresize = () => {
        setCanvasSize();
    };

    return (
        <div className="canvas-container"
            onWheel={scaleCanvas}
            onMouseDown={chartMouseDownHandler}
            onMouseUp={canvasMouseUpHandler}
            onMouseMove={canvasMouseMoveHandler}>
            <canvas id="canvas" width={width} height={height}></canvas>
        </div>
    )
}