import React from "react";
import "./PriceScale.scss";

interface PriceScaleProps {
    cameraY: number;
    highestPrice: number;
    lowestPrice: number;
    panelHeight: number;
}

const PriceScale: React.FC<PriceScaleProps> = ({ cameraY, highestPrice, lowestPrice, panelHeight }) => {
    if (lowestPrice === 0 || highestPrice === 0) {
        return (<></>);
    }


    const numberOfTicks = 10;
    const step = (highestPrice - lowestPrice) / numberOfTicks;
    let ticks = [];
    const priceRange = highestPrice - lowestPrice;
    const offsetY = (cameraY / panelHeight) * priceRange;

    for (let i = 0; i <= numberOfTicks; i++) {
        ticks.push((lowestPrice + i * step) + offsetY);
    }

    const topPrice = Math.max(...ticks);
    const bottomPrice =  Math.min(...ticks);

    return (
        <div className="price-scale">
            {ticks.map((price, index) => (
                <div key={index} className="price-tick" style={{ top: `${(1 - (price - bottomPrice) / (topPrice - bottomPrice)) * 100}%` }}>
                    {Math.round(price)}
                </div>
            ))}
        </div>
    );
};

export default PriceScale;
