import React, { LegacyRef } from 'react';
import "./PriceScale.scss";

const getRoundStep = (minPrice: number, maxPrice: number) => {
    const range = maxPrice - minPrice;

    if (range >= 1e9) return 1e8; 
    if (range >= 1e6) return 1e4;  
    if (range >= 1e3) return 1e2;  
    if (range >= 1e2) return 10;   
    if (range >= 1e1) return 1;    
    return 1;                      
};

const roundValue = (value: number, step: number) => {
    return Math.round(value / step) * step;
};

interface PriceScaleProps {
    minPrice: number;
    maxPrice: number;
    ticksCount: number;
    elementRef: LegacyRef<HTMLDivElement>;
}

const PriceScale: React.FC<PriceScaleProps> = ({ minPrice, maxPrice, ticksCount, elementRef }) => {
    const step = getRoundStep(minPrice, maxPrice);

    const generateTicks = () => {
        const stepValue = (maxPrice - minPrice) / (ticksCount - 1);
        const ticks = [];
        for (let i = 0; i < ticksCount; i++) {
            const value = minPrice + stepValue * i;
            ticks.push(roundValue(value, step));
        }
        return ticks;
    };

    const ticks = generateTicks();

    return (
        <div className="price-scale-panel" ref={elementRef}>
            {
                ticks.map((tick, index) => (
                    <div
                        key={index}
                        className="tick"
                        style={{
                            top: `${(1 - (tick - minPrice) / (maxPrice - minPrice)) * 100}%`,
                        }}
                    >
                        <div>{tick} </div>
                    </div>
                ))}
        </div>
    );


};

export default PriceScale;
