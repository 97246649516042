export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export interface Range {
    min: number;
    max: number;
}

export interface Point {
    x: number;
    y: number;
}

export enum ElementId {
    PriceScale="DefaultAxisId",
    TimeScale="DefaultAxisId",
}

export interface ChartEvent {
    priceScaleActive: boolean;
    timeScaleActive: boolean;
    scrollingActive: boolean;
}

export interface UserSettings {
    minPriceGap: number;
    minTimeGap: number;
    priceTickCount: number;
    timeTickCount: number;
    scaleStepPercents: number;
    scrollingStepPercents: number;
}

export const userConfig: UserSettings = {
    minPriceGap: 1,
    minTimeGap: 10000,
    priceTickCount: 10,
    timeTickCount: 5,
    scaleStepPercents: 10,
    scrollingStepPercents: 1
}
