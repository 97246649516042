import * as React from "react";
import "./CandlestickChart.scss";

import { FastCandlestickRenderableSeries, SciChartOverview, FastOhlcRenderableSeries, SciChartSurface, NumberRange, AxisBase2D } from "scichart";
import { SciChartReact, SciChartNestedOverview, TResolvedReturnType } from "scichart-react";
import { drawExample, overviewOptions } from "./drawExample";
import { appTheme } from "./theme";
import { useEffect, useRef, useState } from "react";
import { zoomInStep, zoomOutStep } from "../constants";
import PriceScale from "./PriceScale/PriceScale";
import { ChartEvent, DATE_FORMAT, ElementId, Point, Range, userConfig } from "./entities";
import TimeScale from "./TimeScale/TimeScale";

export default function CandlestickChart() {
    const [canvasSize, setCanvasSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
    const [candlestickChartSeries, setCandlestickChartSeries] = useState<FastCandlestickRenderableSeries>();
    const [ohlcChartSeries, setOhlcChartSeries] = useState<FastOhlcRenderableSeries>();
    const [sciChartSurface, setSciChartSurface] = useState<SciChartSurface>();
    const [dataSource, setDataSource] = useState<string>("Random");

    const chartRef = useRef<HTMLDivElement>(null);
    const priceRef = useRef<HTMLDivElement>(null);
    const timeRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const dragPosition = useRef<Point>({ x: 0, y: 0 });
    const events = useRef<ChartEvent>({ timeScaleActive: false, priceScaleActive: false, scrollingActive: false });

    const [dragDelta, setDragDelta] = useState<Point>({ x: 0, y: 0 });
    const [priceRange, setPriceRange] = useState<Range>({ min: 0, max: 0 });
    const [timeRange, setTimeRange] = useState<Range>({ min: 0, max: 0 });
    const [priceAxis, setPriceAxis] = useState<AxisBase2D | null>();
    const [timeAxis, setTimeAxis] = useState<AxisBase2D | null>();
    const [zoomDeltaX, setZoomDeltaX] = useState<number>(0);
    const [zoomDeltaY, setZoomDeltaY] = useState<number>(0);

    SciChartSurface.useWasmFromCDN();
    const initFunc = drawExample(dataSource);

    const scalePriceRange = () => {
        const gap = priceRange.max - priceRange.min;

        if (gap <= userConfig.minPriceGap && zoomDeltaY < 0) {
            return;
        }

        const valueToChange = gap / 100 * userConfig.scaleStepPercents;

        const topValueToCahnge: number = zoomDeltaY > 0 ? valueToChange : valueToChange * -1;
        const bottomValueToChange: number = topValueToCahnge * -1;

        setPriceRange({ min: priceRange.min + bottomValueToChange, max: priceRange.max + topValueToCahnge });
    }

    const scaleTimeRange = (delta: number) => {
        const gap = timeRange.max - timeRange.min;

        if (gap <= userConfig.minTimeGap && delta < 0) {
            return;
        }

        const valueToChange = gap / 100 * userConfig.scaleStepPercents;

        const topValueToCahnge: number = delta > 0 ? valueToChange : valueToChange * -1;
        const bottomValueToChange: number = topValueToCahnge * -1;

        setTimeRange({ min: timeRange.min + bottomValueToChange, max: timeRange.max + topValueToCahnge });
    }

    const scrollChart = (x: number, y: number) => {
        const timeGap = timeRange.max - timeRange.min;
        const priceGap = priceRange.max - priceRange.min;

        const timeCoeficient = x === 0 ? 0 : x > 0 ? -1 : 1;
        const priceCoeficient = y === 0 ? 0 : y > 0 ? 1 : -1;

        const priceToChange = priceGap / 100 * userConfig.scrollingStepPercents * priceCoeficient;
        const timeToChange = timeGap / 100 * userConfig.scrollingStepPercents * timeCoeficient;

        setTimeRange({ min: timeRange.min + timeToChange, max: timeRange.max + timeToChange });
        setPriceRange({ min: priceRange.min + priceToChange, max: priceRange.max + priceToChange });
    }

    useEffect(() => {
        //@ts-ignore
        const yAxis = sciChartSurface?.yAxes?.items.find(scale => scale.id === ElementId.PriceScale);
        //@ts-ignore
        const xAxis = sciChartSurface?.xAxes.items.find(scale => scale.id === ElementId.TimeScale);

        if (yAxis == null || xAxis == null) {
            return;
        }

        setPriceAxis(yAxis);
        setTimeAxis(xAxis);

        setPriceRange({ ...yAxis.visibleRange });
        setTimeRange({ ...xAxis.visibleRange });

    }, [sciChartSurface]);

    useEffect(() => {
        if (priceRange.max === 0 && priceRange.min === 0) {
            return;
        }

        if (priceAxis == null) {
            return;
        }

        priceAxis.visibleRange = new NumberRange(priceRange.min, priceRange.max);

    }, [priceRange]);

    useEffect(() => {
        if (timeRange.max === 0 && timeRange.min === 0) {
            return;
        }

        if (timeAxis == null) {
            return;
        }

        timeAxis.visibleRange = new NumberRange(timeRange.min, timeRange.max);
    }, [timeRange]);

    useEffect(() => {
        if (zoomDeltaY === 0) {
            return;
        }

        scalePriceRange();
    }, [zoomDeltaY]);


    useEffect(() => {
        console.log(dragDelta);
        scrollChart(dragDelta.x, dragDelta.y);
    }, [dragDelta]);

    useEffect(() => {
        if (zoomDeltaX === 0) {
            return;
        }

        scaleTimeRange(zoomDeltaX);
        setZoomDeltaX(0);
    }, [zoomDeltaX]);

    const onMouseMove = (event: MouseEvent): void => {
        if (
            !events.current.priceScaleActive &&
            !events.current.timeScaleActive &&
            !events.current.scrollingActive
        ) {
            return;
        }

        const x = (event.clientX - dragPosition.current.x) / 1;
        const y = (event.clientY - dragPosition.current.y) / 1;
        dragPosition.current = { x: event.clientX, y: event.clientY };

        if (events.current.scrollingActive) {
            setDragDelta({ x, y });
        } else if(events.current.timeScaleActive) {
            setZoomDeltaX(x);
        } else if(events.current.priceScaleActive) {
            setZoomDeltaY(y);
        }
    };

    const onPriceMouseDown = (event: MouseEvent): void => {
        events.current.priceScaleActive = true;
        dragPosition.current = { x: event.clientX, y: event.clientY };
    };

    const onTimeMouseDown = (event: MouseEvent): void => {
        events.current.timeScaleActive = true;
        dragPosition.current = { x: event.clientX, y: event.clientY };
    };


    const onWrapperMouseDown = (event: MouseEvent): void => {
        events.current.scrollingActive = true;
        dragPosition.current = { x: event.clientX, y: event.clientY };
    };

    const onMouseUp = (): void => {
        events.current.priceScaleActive = false;
        events.current.timeScaleActive = false;
        events.current.scrollingActive = false;
    };

    const onMouseLeave = (): void => {
        events.current.priceScaleActive = false;
        events.current.timeScaleActive = false;
        events.current.scrollingActive = false;
    };

    const onMouseWheel = (event: WheelEvent): void => {
        setZoomDeltaX(event.deltaY)
    };

    useEffect(() => {
        if (chartRef.current == null ||
            timeRef.current == null ||
            priceRef.current == null ||
            wrapperRef.current == null
        ) {
            return;
        }

        priceRef.current.addEventListener('mousedown', onPriceMouseDown);
        timeRef.current.addEventListener('mousedown', onTimeMouseDown);
        wrapperRef.current.addEventListener('mousedown', onWrapperMouseDown);
        chartRef.current.addEventListener('mousemove', onMouseMove);
        chartRef.current.addEventListener('mouseup', onMouseUp);
        chartRef.current.addEventListener('wheel', onMouseWheel);
        chartRef.current.addEventListener('mouseleave', onMouseLeave);


        return (): void => {
            if (chartRef.current == null ||
                timeRef.current == null ||
                priceRef.current == null ||
                wrapperRef.current == null
            ) {
                return;
            }

            priceRef.current.removeEventListener('mousedown', onPriceMouseDown);
            timeRef.current.removeEventListener('mousedown', onTimeMouseDown);
            wrapperRef.current.removeEventListener('mousedown', onWrapperMouseDown);
            chartRef.current.removeEventListener('mousemove', onMouseMove);
            chartRef.current.removeEventListener('mouseup', onMouseUp);
            chartRef.current.removeEventListener('wheel', onMouseWheel);
            chartRef.current.removeEventListener('mouseleave', onMouseLeave);
        };
    }, []);

    useEffect(() => {
        if (sciChartSurface == null) {
            return;
        }

        console.log(sciChartSurface?.xAxes);
    }, [sciChartSurface?.yAxes]);

    return (
        <div className="canvas-container" ref={chartRef}>
            <PriceScale elementRef={priceRef} minPrice={priceRange.min} maxPrice={priceRange.max} ticksCount={userConfig.priceTickCount} />
            <TimeScale elementRef={timeRef} startDate={timeRange.min} endDate={timeRange.max} tickCount={userConfig.timeTickCount} dateFormat={DATE_FORMAT} />
            <div className="wrapper" ref={wrapperRef}>
                <div style={{ background: appTheme.DarkIndigo, height: '100%' }}>
                    <SciChartReact
                        key={dataSource}
                        initChart={initFunc}
                        onInit={(initResult: TResolvedReturnType<typeof initFunc>) => {
                            const { sciChartSurface, ohlcSeries, candlestickSeries } = initResult;
                            setCandlestickChartSeries(candlestickSeries);
                            setOhlcChartSeries(ohlcSeries);
                            setSciChartSurface(sciChartSurface);
                        }}
                        style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}
                        innerContainerProps={{ style: { flexBasis: "90%", flexGrow: 1, flexShrink: 1 } }}
                    >
                        <SciChartNestedOverview
                            style={{ flexBasis: "10%", flexGrow: 1, flexShrink: 1 }}
                            options={overviewOptions}
                        />
                    </SciChartReact>
                </div>
            </div>
        </div>
    );
}