import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { zoomInStep, zoomOutStep } from "../constants";
import "./CandleJapaneeseChart.scss";
import TimeScale from "../TimeScale/TimeScale";
import PriceScale from "../PriceScale/PriceScale";

export interface Candle {
    id: number;
    timeClose: number; // timestamp
    open: number;
    high: number;
    low: number;
    close: number;
}

interface CandleJapaneeseChartProps {
    candles: Candle[];
    canvasSize: { width: number; height: number };
    cameraOffsetX: number;
    cameraOffsetY: number;
    zoomLevel: number;
    cameraRight: number;
    cameraLeft: number;
}

const CandleJapaneeseChart: React.FC<CandleJapaneeseChartProps> = ({ candles, canvasSize, cameraOffsetX, cameraOffsetY, zoomLevel, cameraLeft, cameraRight }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [camera, setCamera] = useState<THREE.OrthographicCamera>(new THREE.OrthographicCamera(
        -canvasSize.width / 2,
        canvasSize.width / 2,
        canvasSize.height / 2,
        -canvasSize.height / 2,
        0.1,
        1000
    ));
    const [lowestPrice, setLowestPrice] = useState<number>(0);
    const [highestPrice, setHighestPrice] = useState<number>(0);


    useEffect(() => {
        if (!canvasRef.current) return;

        const scene = new THREE.Scene();
        const camera = new THREE.OrthographicCamera(
            -canvasSize.width / 2,
            canvasSize.width / 2,
            canvasSize.height / 2,
            -canvasSize.height / 2,
            0.1,
            1000
        );
        setCamera(camera);
        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });

        renderer.setSize(canvasSize.width, canvasSize.height);
        renderer.setClearColor(0x0c0c0c);

        const light = new THREE.AmbientLight(0xffffff, 1);
        scene.add(light);

        let maxPrice = -Infinity;
        let minPrice = Infinity;

        candles.forEach(candle => {
            maxPrice = Math.max(maxPrice, candle.high);
            minPrice = Math.min(minPrice, candle.low);
        });

        setLowestPrice(minPrice);
        setHighestPrice(maxPrice);

        const candleWidth = 10;
        const spaceBetweenCandles = 5;

        const normalize = (price: number) => {
            return ((price - minPrice) / (maxPrice - minPrice)) * canvasSize.height;
        };

        candles.forEach((candle, index) => {
            const xPosition = index * (candleWidth + spaceBetweenCandles) - canvasSize.width / 2;

            const openY = normalize(candle.open);
            const closeY = normalize(candle.close);
            const highY = normalize(candle.high);
            const lowY = normalize(candle.low);

            const color = candle.close > candle.open ? 0x00ff00 : 0xff0000;

            const geometry = new THREE.PlaneGeometry(candleWidth, Math.abs(openY - closeY));
            const material = new THREE.MeshBasicMaterial({ color, side: THREE.DoubleSide });
            const candleMesh = new THREE.Mesh(geometry, material);

            candleMesh.position.set(xPosition, (openY + closeY) / 2 - canvasSize.height / 2, 0);
            scene.add(candleMesh);

            const materialShadow = new THREE.LineBasicMaterial({ color: 0xc3c3c3 });
            const points = [
                new THREE.Vector3(xPosition, highY - canvasSize.height / 2, 0),
                new THREE.Vector3(xPosition, lowY - canvasSize.height / 2, 0),
            ];
            const geometryShadow = new THREE.BufferGeometry().setFromPoints(points);
            const shadowLine = new THREE.Line(geometryShadow, materialShadow);
            shadowLine.position.z = -1;
            scene.add(shadowLine);
        });

        camera.position.z = 50;

        const animate = () => {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        };

        animate();

        return (): void => {
            while (scene.children.length > 0) {
                scene.remove(scene.children[0]);
            }
            renderer.dispose();
        };
    }, [candles, canvasSize]);


    useEffect(() => {
        camera.left = cameraLeft;
        camera.right = cameraRight;
        camera.updateProjectionMatrix();
    }, [cameraLeft, cameraRight]);


    useEffect(() => {
        camera.position.x = cameraOffsetX;
        camera.position.y = cameraOffsetY;
    }, [cameraOffsetX, cameraOffsetY]);

    return (
        <>
            <canvas ref={canvasRef} />

            <PriceScale
                cameraY={cameraOffsetY}
                panelHeight={canvasSize.height}
                highestPrice={highestPrice}
                lowestPrice={lowestPrice}></PriceScale>
            <TimeScale
                timeValues={candles}
                panelWidth={canvasSize.width}
                zoom={zoomLevel}
                positionX={cameraOffsetX}></TimeScale>
        </>
    );
};

export default CandleJapaneeseChart;